/*
 *   Based on the W3C example on https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-2/tabs.html
 */

import Utils from "../Helpers/Utils";

const Tabs = (() => {
    /* use this to match your breakpoint for mobile views */
    const breakpointTabs = window.matchMedia("(min-width: 768px)");
    const SELECTOR_ELEMENT = '[data-toggle="tabs"]';
    const SELECTOR_TAB = '[role="tab"]';
    const SELECTOR_PANEL = '[role="tabpanel"]';

    const tabsSelector = document.querySelectorAll(SELECTOR_ELEMENT);

    // For easy reference
    const keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46,
        enter: 13,
        space: 32
    };

    const getTabList = (tab) => {

    };

    const toggleTab = (tab, setFocus = true) => {
        const state = tab.getAttribute("aria-selected") === 'true';

        // Deactivate all other tabs
        resetTabs(tab);

        // Remove tabindex attribute
        tab.removeAttribute("tabindex");

        // Toggle the aria-selected state
        tab.setAttribute("aria-selected", !state);

        // Get the value of aria-controls (which is an ID)
        var controls = tab.getAttribute("aria-controls");

        // Remove d-none class from tab panel to make it visible
        Utils.toggleClass(document.getElementById(controls), "d-none", state);

        // Set focus when required
        if (setFocus) {
            tab.focus();
        }
    };

    const resetTabs = (tab) => {
        const tabsElements = tab.closest(SELECTOR_ELEMENT);
        const tabs = tabsElements.querySelectorAll(SELECTOR_TAB);

        tabs.forEach(tabElement => {
            tabElement.setAttribute("aria-selected", "false");
            document.getElementById(tabElement.getAttribute("aria-controls")).classList.add("d-none");
        });
    };

    // When a tablist’s aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    const determineOrientation = (event) => {
        var key = event.keyCode;
        var vertical = tabSelector.getAttribute("aria-orientation") == "vertical";
        var proceed = false;

        if (vertical) {
            if (key === keys.up || key === keys.down) {
                event.preventDefault();
                proceed = true;
            }
        } else {
            if (key === keys.left || key === keys.right) {
                proceed = true;
            }
        }

        if (proceed) {
            switchTabOnArrowPress(event);
        }
    };

    // When a tab is clicked, activateTab is fired to activate it
    const clickEventListener = (e) => {
        const target = e.target.closest(SELECTOR_TAB);
        if (target) {
            toggleTab(target, false);
        }
    };

    document.addEventListener("click", clickEventListener);

    const keydownEventListener = (e) => {
    };

    const keyupEventListener = (e) => {
        const target = document.activeElement;
        const key = e.keyCode;

        if (target.matches(SELECTOR_TAB)) {
            switch (key) {
                case keys.left:
                case keys.right:
                    determineOrientation(e);
                break;
                case keys.delete:
                    determineDeletable(e);
                break;
                case keys.enter:
                case keys.space:
                    toggleTab(target);
                    break;
            }   
        }
    };

    document.addEventListener("keydown", keydownEventListener);
    document.addEventListener("keyup", keyupEventListener);

    Array.from(tabsSelector).forEach((tabSelector) => {
        const tabs = tabSelector.querySelectorAll(SELECTOR_TAB);
        const panels = tabSelector.querySelectorAll(SELECTOR_PANEL);
       
        if (!breakpointTabs.matches) { 
            Array.from(tabs).forEach((tab) => {
                tab.removeAttribute('tabindex');
            });
        }
   
        // Add or subtract depending on key pressed
        var direction = {
            37: -1,
            38: -1,
            39: 1,
            40: 1
        };
   
        // Bind listeners
        for (var i = 0; i < tabs.length; ++i) {
            addListeners(i);
        }

        function addListeners(index) {
            // tabs[index].addEventListener("keydown", keydownEventListener);
            // tabs[index].addEventListener("keyup", keyupEventListener);
   
            // Build an array with all tabs (<button>s) in it
            tabs[index].index = index;
        }
   
   
        // Handle keydown on tabs
        function keydownEventListener(event) {
            var key = event.keyCode;
   
            switch (key) {
            case keys.end:
                event.preventDefault();
                // Activate last tab
                focusLastTab();
                break;
            case keys.home:
                event.preventDefault();
                // Activate first tab
                focusFirstTab();
                break;
   
            // Up and down are in keydown
            // because we need to prevent page scroll >:)
            case keys.up:
            case keys.down:
                determineOrientation(event);
                break;
            }
        }
   
        // Handle keyup on tabs
        function keyupEventListener(event) {
            var key = event.keyCode;
   
            switch (key) {
                case keys.left:
                case keys.right:
                    determineOrientation(event);
                break;
                case keys.delete:
                    determineDeletable(event);
                break;
                case keys.enter:
                case keys.space:
                    toggleTab(event.target);
                    break;
            }   
        }
   
        // When a tablist’s aria-orientation is set to vertical,
        // only up and down arrow should function.
        // In all other cases only left and right arrow function.
        function determineOrientation(event) {
            var key = event.keyCode;
            var vertical = tabSelector.getAttribute("aria-orientation") == "vertical";
            var proceed = false;
   
            if (vertical) {
                if (key === keys.up || key === keys.down) {
                    event.preventDefault();
                    proceed = true;
                }
            } else {
                if (key === keys.left || key === keys.right) {
                    proceed = true;
                }
            }
   
            if (proceed) {
                switchTabOnArrowPress(event);
            }
        }
   
        // Either focus the next, previous, first, or last tab
        // depending on key pressed
        function switchTabOnArrowPress(event) {
            var pressed = event.keyCode;
   
            if (direction[pressed]) {
                var target = event.target;
                if (target.index !== undefined) {
                    if (tabs[target.index + direction[pressed]]) {
                        tabs[target.index + direction[pressed]].focus();
                    } else if (pressed === keys.left || pressed === keys.up) {
                        focusLastTab();
                    } else if (pressed === keys.right || pressed == keys.down) {
                        focusFirstTab();
                    }
                }
            }
        }
      
        // Deactivate all tabs and tab panels
        function deactivateTabs() {
            for (var t = 0; t < tabs.length; t++) {
                if (breakpointTabs.matches) {
                    tabs[t].setAttribute("tabindex", "-1");
                }
                tabs[t].setAttribute("aria-selected", "false");
            }

            for (var p = 0; p < panels.length; p++) {
                panels[p].classList.add("d-none");
            }
        }
   
        // Make a guess
        function focusFirstTab() {
            tabs[0].focus();
        }
   
        // Make a guess
        function focusLastTab() {
            tabs[tabs.length - 1].focus();
        }
    });

    
})();

export default Tabs;