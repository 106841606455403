"use strict";
import LazyLoad from 'vanilla-lazyload';
import Glide from '@glidejs/glide';
import Utils from './Helpers/Utils';
import Tabs from './Components/Tabs';

const initImages = () => {
    const getImages = new LazyLoad();
    getImages.update();
};

const initDropdowns = () => {
    const toggleDropdown = (e) => {
        const dropdownToggle = e.target.closest('[data-toggle="dropdown"]');
        
        if(dropdownToggle) {
            // Get target 
            const dropdownMenu = document.querySelector(dropdownToggle.dataset.target);

            // Get state
            const state = dropdownToggle.getAttribute('aria-expanded') === 'true';

            Utils.toggleClass(dropdownMenu, 'show', !state);
            dropdownToggle.setAttribute('aria-expanded', !state);
        }
    };

    document.addEventListener('click', toggleDropdown);
};

const initMobileMenu = () => {
    const header = document.querySelector('.header--primary');
    const headerNav = header.querySelector('.header-nav');
    const body = document.body;
    let modal = null;


    const toggleMobileMenu = (e) => {
        const mobileMenuToggle = e.target.closest('[data-toggle="mobile-menu"]');

        if (mobileMenuToggle) {
            e.preventDefault();

            // Get state
            const state = mobileMenuToggle.getAttribute('aria-expanded') === 'true';

            if (!state) {
                modal = document.createElement('div');
                modal.classList.add('modal-backdrop', 'js-panel-close', 'fade', 'show');
        
                body.appendChild(modal);
                body.classList.add('modal-open');        
            } else {
                body.classList.remove('modal-open');
                body.removeChild(modal);
            }

            Utils.toggleClass(headerNav, 'show', !state);
            mobileMenuToggle.setAttribute('aria-expanded', !state);
        }
    };

    document.addEventListener('click', toggleMobileMenu);
};

const initGlider = () => {
    const selector = '[data-toggle="glide"]';
    const gliders = document.querySelectorAll(selector);

    const gliderClasses = {
        direction: {
            ltr: 'glide-ltr',
            rtl: 'glide-rtl',
        },
        slider: 'glide-slider',
        carousel: 'glide-carousel',
        swipeable: 'glide-swipeable',
        dragging: 'glide-dragging',
        cloneSlide: 'glide-slide-clone',
        activeNav: 'glide-bullet-active',
        activeSlide: 'glide-slide-active',
        disabledArrow: 'glide-arrow-disabled',
        nav: {
            active: 'glide-bullet-active',
        }
    }

    const commonSettings = {
        type: 'carousel',
        autoplay: false,
        gap: 32,
        startAt: 0,
        rewind: false,
        classes: {...gliderClasses},
    };

    if (gliders) {

        gliders.forEach(glider => {
            const isSpotlight = glider.classList.contains('is-spotlight');
            let gliderSettings = {};

            if (isSpotlight) {
                gliderSettings = { 
                    perView: 1,
                    peek: {
                        before: 30,
                        after: 100,
                    },
                }
            } else {
                gliderSettings = {
                    perView: 3,
                    breakpoints: {
                        768: {
                            perView: 1,
                            peek: {
                                before: 30,
                                after: 100,
                            },        
                        },
                    },
                }
            }

            new Glide(glider, {
                ...commonSettings,
                ...gliderSettings,                
            }).mount();
        });
    }
};



Utils.domLoaded(() => {
    initImages();
    initDropdowns();
    initMobileMenu();
    initGlider();
});

Utils.windowLoaded(() => {
});
